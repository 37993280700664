import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import NotificationProductCard from '../NotificationProductCard';
import styles from './ProfileNotificationCenter.module.scss';
import { ReactComponent as Empty } from '../../images/icons/empty-tracking.svg';
import Button, { SECONDARY } from '../Button/Button';
import { Link } from 'react-router-dom';
import { useStockSubscription } from '../../utils/useStockSubscriptions';

const ProfileNotificationCenter = () => {
  const { backInStockSubscriptions, isGetStockSubscription, unsubscribeProduct } = useStockSubscription();

  const sortedStockSubscription = backInStockSubscriptions
    ?.filter((product) => product.active)
    ?.sort((a, b) => new Date(b.added) - new Date(a.added));

  const outOfStockProducts = sortedStockSubscription.filter((product) => !product.stock);
  const inStockProducts = sortedStockSubscription.filter((product) => product.stock);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <h3 className={styles.title}>
        <FormattedMessage id="notificationCenter.title" defaultMessage="Notification center" />
      </h3>
      {!!backInStockSubscriptions.length && (
        <p className={styles.desc}>
          <FormattedMessage
            id="notificationCenter.subHeader"
            defaultMessage="You are subscribed to the status of these products. As soon as the product is available, we will send you an email!"
          />
        </p>
      )}
      {isGetStockSubscription && (
        <div className={styles.loaderWrapper}>
          <div className={styles.placeholderNode}></div>
        </div>
      )}
      {!isGetStockSubscription && (
        <>
          {!backInStockSubscriptions.length && (
            <div className={styles.emptyRoot}>
              <Empty />
              <h5>
                <FormattedMessage id="notificationCenter.empty" defaultMessage="You are not tracking any product" />
              </h5>
              <p>
                <FormattedMessage
                  id="notificationCenter.emptySubtitle"
                  defaultMessage="If the product is out of stock, you can start tracking it. We will notify you when the product appears again!"
                />
              </p>
              <Link to="/">
                <Button variation={SECONDARY}>
                  <FormattedMessage id="notificationCenter.goHome" defaultMessage="Go to homepage" />
                </Button>
              </Link>
            </div>
          )}
          {!!backInStockSubscriptions.length && (
            <>
              <h5 className={styles.stockTitle}>
                <FormattedMessage id="notificationCenter.inStock" defaultMessage="In stock" />
              </h5>
              <div className={styles.productContainer}>
                {inStockProducts.map((product) => (
                  <NotificationProductCard key={product.id} product={product} unsubscribeProduct={unsubscribeProduct} />
                ))}
              </div>
              <div className={styles.divider} />
              <h5 className={styles.stockTitle}>
                <FormattedMessage id="notificationCenter.outOfStock" defaultMessage="Out of stock" />
              </h5>
              <div className={styles.productContainer}>
                {outOfStockProducts.map((product) => (
                  <NotificationProductCard key={product.id} product={product} unsubscribeProduct={unsubscribeProduct} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileNotificationCenter;
