import { useDispatch, useSelector } from 'react-redux';
import { getValidResponseBody, productApi, userApi } from '../api';
import {
  addBackInStockSubscription,
  removeBackInStockSubscription,
  setBackInStockSubscriptions,
} from '../state/stockSubscriptions/stockSubscriptions';
import { useAuth0Wrapper } from './auth0';
import { useEffect, useState } from 'react';
import useNotifications from '../components/NotificationPopup/useNotifications';
import { useIntl } from 'react-intl';

export const useStockSubscription = () => {
  const intl = useIntl();
  const { tokenClaims } = useAuth0Wrapper();
  const authToken = tokenClaims?.__raw;
  const headers = { Authorization: `Bearer ${authToken}` };
  const dispatch = useDispatch();
  const { backInStockSubscriptions } = useSelector((state) => state.stockSubscription);
  const [isGetStockSubscription, setIsGetStockSubscription] = useState(true);
  const { sendNotification } = useNotifications();

  const getStockSubscriptions = async () => {
    try {
      const response = await userApi.getBackInStockSubscriptions({ headers });
      if (response.ok) {
        dispatch(setBackInStockSubscriptions(getValidResponseBody(response)));
      }
    } finally {
      setIsGetStockSubscription(false);
    }
  };

  const subscribeToProduct = async (productId, productInfo) => {
    let isSuccess = false;
    try {
      const response = await productApi.subscribeBackInStock({ headers, body: JSON.stringify({ productId }) });
      if (response.ok) {
        isSuccess = true;
        dispatch(addBackInStockSubscription(productInfo));
      } else {
        sendNotification(intl.formatMessage({ id: 'common.error.api' }));
      }
    } catch (error) {
      console.error(error);
      sendNotification(intl.formatMessage({ id: 'common.error.api' }));
    }
    return isSuccess;
  };

  const unsubscribeProduct = async (subscriptionId, productInfo) => {
    let isSuccess = false;
    try {
      const response = await productApi.unsubscribeProduct({ headers, body: JSON.stringify({ subscriptionId }) });
      if (response.ok) {
        isSuccess = true;
        dispatch(removeBackInStockSubscription(productInfo));
      } else {
        sendNotification(intl.formatMessage({ id: 'common.error.api' }));
      }
    } catch (error) {
      console.error(error);
      sendNotification(intl.formatMessage({ id: 'common.error.api' }));
    }
    return isSuccess;
  };

  useEffect(() => {
    if (!authToken) {
      return;
    }
    getStockSubscriptions();
  }, [authToken]);

  return { subscribeToProduct, backInStockSubscriptions, isGetStockSubscription, unsubscribeProduct };
};
