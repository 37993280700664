import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import getCDNImage from '../../utils/getCDNImage';
import Button, { SECONDARY } from '../Button/Button';
import Product from '../Product/Product';
import styles from './NotificationProductCard.module.scss';
import { ReactComponent as InStock } from '../../images/icons/in-stock.svg';
import { ReactComponent as OutOfStock } from '../../images/icons/out-stock.svg';
import { useNavigate } from 'react-router-dom';

const NotificationProductCard = ({ product, unsubscribeProduct }) => {
  const navigate = useNavigate();
  const status = product.stock ? 'inStock' : 'outStock';
  const [isUnsubscribeProduct, setIsUnsubscribeProduct] = useState(false);
  const handleRemoveProductSubscription = async (subscriptionId) => {
    if (isUnsubscribeProduct || !subscriptionId) {
      return;
    }
    try {
      setIsUnsubscribeProduct(true);
      await unsubscribeProduct(subscriptionId, product);
    } finally {
      setIsUnsubscribeProduct(false);
    }
  };

  return (
    <div className={styles.root}>
      <Product
        adult={product?.adult}
        shopRating={product.shopRating?.rating}
        brand={product?.brand}
        currency={product?.currency || process.env.REACT_APP_WEBSITE_CURRENCY}
        shortDesc={product?.shortDesc}
        EAN={product?.offers?.[0]?.EAN}
        price={product.price}
        originalPrice={product.price_original || product.price}
        id={product.product}
        image={getCDNImage(product?.thumbs?.[0])}
        name={product.name}
        shop={{ name: product.campaign }}
        SKU={product.SKU}
        showProductLink={false}
        // url={product.pdp_path}
        status={product.new}
        shopId={product?.shop_id}
        merchantPid={product?.merchant_product_id}
        onClick={() => navigate(product.pdp_path)}
        classList={{ root: styles.productCard }}
      />
      <div className={styles.status}>
        <p className={styles.statusTitle}>
          <FormattedMessage id="notificationCenter.status.about" defaultMessage="Over product" />
        </p>
        <div className={styles.stockWrapper}>
          <FormattedMessage id="notificationCenter.status.status" defaultMessage="Status" />
          <span className={classNames(styles.stockStatus, { [styles.outOfStock]: status === 'outStock' })}>
            {status === 'inStock' ? <InStock /> : <OutOfStock />}{' '}
            {status === 'inStock' ? (
              <FormattedMessage id="notificationCenter.inStock" defaultMessage="In stock" />
            ) : (
              <FormattedMessage id="notificationCenter.outOfStock" defaultMessage="Out of stock" />
            )}
          </span>
        </div>
        <p className={classNames(styles.statusTitle, styles.subscribe)}>
          <FormattedMessage id="notificationCenter.status.subscription" defaultMessage="About subscription" />
        </p>
        {!!product.added && (
          <p>
            <FormattedMessage
              id="notificationCenter.status.date"
              defaultMessage="Addition date: {date}"
              values={{ date: new Date(product.added).toLocaleDateString() }}
            />
          </p>
        )}
        {!!product.email && (
          <p>
            <FormattedMessage
              id="notificationCenter.status.email"
              defaultMessage="Email from us: {email}"
              values={{ email: product.email }}
            />
          </p>
        )}
        <Button
          variation={SECONDARY}
          inverted
          classList={{ root: styles.action }}
          onClick={() => handleRemoveProductSubscription(`${product.id}`)}
        >
          <FormattedMessage id="notificationCenter.status.unsubscribe" defaultMessage="Unsubscribe" />
        </Button>
      </div>
    </div>
  );
};

export default NotificationProductCard;
